@import "../styles/colors";

.logo-banner{
  height:50px;
  background: $primary-color;
  color: $font-color-light;
  line-height: 50px;
  text-align: left;
  img{
    padding: 0 10px;
  }
}