$primary-color: #334054;
$secondary-color: #5171a0;
$tertiary-color: #eaeef1;
$accent-color-1: #f5a623;

$success-color: #72a23d;
$failure-color: #d00;

$font-stack: Helvetica, sans-serif;
$font-color-light: #ddd;
$font-color: #999;
$border-color:#C8C8C8;
$font-color-dark: #333;

$status-bar-success:linear-gradient(-90deg, #699C31 0%, #A2CB5F 100%);