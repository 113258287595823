@import "../styles/colors";

.hero-container{
  height: 250px;
  position: relative;
  overflow: hidden;
}
.hero-image{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  z-index: -1;
}